<template>
    <div>
        <h1>
            {{ $store.state.language.layout.bread_crumbs.customer_register_form }}
        </h1>
        <div class="row">
            <div class="col-md-6">
                <label for="industry">
                    {{ $store.state.language.agent.list_column.industry }}
                </label>
                <select id="industry" class="form-control" v-model="formData.industry">
                    <option v-for="(i, ik) in $store.state.language.agent.form_industry_option" :value="ik"
                        :key="`i${ik}`">
                        {{ i }}
                    </option>
                </select>
            </div>
            <div class="col-12">
                <label for="company_name">
                    {{ $store.state.language.agent.list_column.company_name }}
                    <span class="red">*</span>
                </label>
                <input type="text" class="form-control" id="company_name" v-model="formData.company_name"
                    :class="formcheck && formData.company_name == '' ? 'is-invalid' : ''" />
                <div class="invalid-feedback" v-if="formcheck && formData.company_name == ''">
                    {{ $store.state.language.agent.list_column.company_name }}&nbsp;
                    {{ $store.state.language.agent.form_invaild.required }}
                </div>
            </div>
            <div class="col-12">
                <label for="url">
                    {{ $store.state.language.agent.list_column.url }}
                    <span class="red">*</span>
                </label>
                <input type="text" class="form-control" id="url" v-model="formData.url"
                    :class="formcheck && formData.url == '' ? 'is-invalid' : ''" />
                <div class="invalid-feedback" v-if="formcheck && formData.url == ''">
                    {{ $store.state.language.agent.list_column.url }}&nbsp;
                    {{ $store.state.language.agent.form_invaild.required }}
                </div>
            </div>
            <div class="col-md-6">
                <label for="machine_install">
                    {{ $store.state.language.agent.list_column.machine_install }}
                    <span class="red">*</span>
                </label>
                <input type="text" class="form-control" id="machine_install" v-model="formData.machine_install"
                    :class="formcheck && formData.machine_install == '' ? 'is-invalid' : '' " />
                <div class="invalid-feedback" v-if="formcheck && formData.machine_install == ''">
                    {{ $store.state.language.agent.list_column.machine_install }}&nbsp;
                    {{ $store.state.language.agent.form_invaild.required }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="required_model">
                    {{ $store.state.language.agent.list_column.required_model }}
                    <span class="red">*</span>
                </label>
                <select id="industry" class="form-control" v-model="formData.required_model_selected"
                    @change="formData.required_model_array.push(formData.required_model_selected); formData.required_model_selected = ''; formData.required_model = formData.required_model_array.join('、');"
                    :class="formcheck && formData.required_model == '' ? 'is-invalid' : ''">
                    <option v-for="(m, mk) in option_model" :value="mk" :key="`m${mk}`">
                        {{ m }}
                    </option>
                </select>
                <input type="hidden" class="form-control" id="required_model" v-model="formData.required_model" />
                <div class="invalid-feedback" v-if="formcheck && formData.required_model == ''">
                    {{ $store.state.language.agent.list_column.required_model }}&nbsp;
                    {{ $store.state.language.agent.form_invaild.required }}
                </div>
            </div>
            <div class="col-md-12">
                <a class="btn btn-outline-info d-inline-block text-start mt-1 me-1"
                    v-for="(m, mk) in formData.required_model_array" :key="`div_m${mk}`"
                    @click="formData.required_model_array.splice(mk, 1); formData.required_model = formData.required_model_array.join('、');">
                    {{ m }} &nbsp;
                    <i class="fa fa-times"></i>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label for="contact">
                    {{ $store.state.language.agent.list_column.contact }}
                </label>
                <input type="text" class="form-control" id="contact" v-model="formData.contact" />
            </div>
            <div class="col-md-3">
                <label for="unit">
                    {{ $store.state.language.agent.list_column.unit }}
                </label>
                <input type="text" class="form-control" id="unit" v-model="formData.unit" />
            </div>
            <div class="col-md-3">
                <label for="rank">
                    {{ $store.state.language.agent.list_column.rank }}
                </label>
                <input type="text" class="form-control" id="rank" v-model="formData.rank" />
            </div>
            <div class="col-12">
                <label for="rank">
                    {{ $store.state.language.agent.list_column.files }}
                    <span class="red">*</span>
                </label>
                <input type="file" class="form-control" multiple v-if="canuploadfile" @change="upload_file"
                    :class="formcheck && formData.files.length==0 ? 'is-invalid' : ''" />
                <i class="fa fa-spin fa-sync" v-show="!canuploadfile"></i>
                <div class="row">
                    <div class="col-md-3" v-for="(f, fk) in formData.files" :key="f">
                        <img :src="'..' + f" style="width: 100px;" />
                        <button class="btn btn-danger btn-sm" @click="formData.files.splice(fk, 1)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="formcheck && formData.files.length == 0">
                    {{ $store.state.language.agent.list_column.files }}&nbsp;
                    {{ $store.state.language.agent.form_invaild.required }}
                </div>
            </div>
            <div class="col-12 my-4">
                <button class="btn btn-primary btn-lg" @click="send_form()">
                    <i class="fa fa-upload"></i>&nbsp;
                    {{ $store.state.language.agent.form_submit }}
                </button>
            </div>
        </div>
    </div>
</template>
<style>
.red {
    color: #f00;
}
</style>
<script>
export default {
    data() {
        return {
            formcheck: false,
            formData: {
                id: "",
                industry: "",
                company_name: "",
                url: "",
                machine_install: "",
                required_model_selected: "",
                required_model: "",
                required_model_array: [],
                contact: "",
                unit: "",
                rank: "",
                files: []
            },
            form_model_option: {},
            canuploadfile: true
        };
    },
    mounted() {
        let vue = this;
        //
        vue.form_model_option = JSON.parse(JSON.stringify(vue.$store.state.language.agent.form_model_option));
        //
        if (vue.$route.params.id) {
            vue.get_data();
        }
        vue.get_models();
    },
    computed: {
        option_model() {
            let vue = this,
                output = {};
            for (let mk in vue.form_model_option) {
                if (mk == '' || !vue.formData.required_model_array.includes(mk)) {
                    output[mk] = vue.form_model_option[mk]
                }
            }
            return output;
        },
    },
    methods: {
        get_data() {
            let vue = this;
            vue.$store
                .dispatch("post_form", {
                    payload: {
                        url: "/api/index.php?act=get_cr",
                        data: { id: vue.$route.params.id }
                    }
                })
                .then(res => {
                    if (res.data.status == "ok") {
                        if (!res.data.data) {
                            // 無資料，轉至列表
                            vue.$router.push("/agent/list");
                        } else {
                            let data = res.data.data;
                            for (let ikey in vue.formData) {
                                if (data[ikey]) {
                                    vue.formData[ikey] = data[ikey];
                                }
                            }
                            // 資料處理
                            vue.formData.files = JSON.parse(vue.formData.files);
                            if (vue.formData.required_model) {
                                vue.formData.required_model_array = vue.formData.required_model.split('、');
                            }
                        }
                    } else {
                        vue.$swal.fire({
                            icon: "error",
                            title: "Error!"
                        });
                    }
                });
        },
        get_models() {
            let vue = this;
            vue.$store
                .dispatch("post_form", {
                    payload: {
                        url: "/api/index.php?act=get_models",
                        data: { }
                    }
                })
                .then(res => {
                    if (res.data.status == "ok") {
                        let data = res.data.ModelsList,
                            array = [];
                        data.forEach(m => {
                            let models = m.group_name;
                            if (!array.includes(models)) {
                                array.push(models);
                                vue.$set(vue.form_model_option, models, models);
                            }
                        });
                    } else {
                        vue.$swal.fire({
                            icon: "error",
                            title: "Error!"
                        });
                    }
                });
        },
        upload_file(e) {
            let vue = this;
            vue.$swal.fire({
                html: `
                <div style="margin:20px">
                    <i class="fa fa-spin fa-sync fa-4x"></i>
                </div>`,
                showConfirmButton: false,
                allowOutsideClick: false
            });
            vue.$store
                .dispatch("post_form", {
                    payload: {
                        url: "/api/index.php?act=upload_file",
                        data: { file: e.target.files[0] }
                    },
                    before() {
                        vue.canuploadfile = false;
                    },
                    header: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    vue.canuploadfile = true;
                    vue.$swal.close();
                    vue.formData.files.push(res.data.path);
                });
        },
        send_form() {
            let vue = this,
                msg = "";
            vue.formcheck = true;
            if (vue.formData.company_name == "") {
                msg =
                    vue.$store.state.language.agent.list_column.company_name +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.url == "") {
                msg =
                    vue.$store.state.language.agent.list_column.url +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.machine_install == "") {
                msg =
                    vue.$store.state.language.agent.list_column.machine_install +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.required_model == "") {
                msg =
                    vue.$store.state.language.agent.list_column.required_model +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.files.length == 0) {
                msg =
                    vue.$store.state.language.agent.list_column.files +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            }
            if (msg == "") {
                vue.$swal.fire({
                    html: `
                    <div style="margin:20px">
                        <i class="fa fa-spin fa-sync fa-4x"></i>
                    </div>
                    `,
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                let form = {};
                for (let f in vue.formData) {
                    if (f == "files") {
                        form[f] = JSON.stringify(vue.formData[f]);
                    } else {
                        form[f] = vue.formData[f];
                    }
                }
                vue.$store
                    .dispatch("post_form", {
                        payload: {
                            url: "/api/index.php?act=send_form",
                            data: form
                        }
                    })
                    .then(res => {
                        if (res.data.status == "ok") {
                            vue.$swal
                                .fire({
                                    icon: "success",
                                    title:
                                        vue.$store.state.language.agent
                                            .form_sent,
                                    timer: 1500
                                })
                                .then(() => {
                                    vue.$router.push("/agent/list");
                                });
                        } else {
                            vue.$swal.fire({
                                icon: "error",
                                title: "Error!"
                            });
                        }
                    });
            } else {
                vue.$swal.fire({
                    icon: "error",
                    title: msg
                });
            }
        }
    }
};
</script>
